import {draftEventPreviewUrlQueryParamName} from '@wix/wix-events-commons-statics'
import {getHeadlessUrl, NavigationType} from '@wix/wix-to-headless-redirect-client'
import {IWixAPI} from '@wix/yoshi-flow-editor'
import {DETAILS_ROUTE} from '../../../../commons/constants/navigation'
import {ReservationState} from '../../../../commons/enums'
import {RouteParams, State} from '../types'
import {isHeadlessSupportEnabled} from '../../../../commons/selectors/experiments'

export const getSectionPath = (state: State) => state.navigation.sectionPath

export const getSlug = (state: State) => state.navigation.slug

export const getRoute = (state: State) => state.navigation.route

export const getReservationState = (state: State) => state.navigation.reservationState

export const getThankYouMessageState = (state: State) => state.navigation.thankYouMessageState

export const isThankYouMessageYes = (state: State) => getThankYouMessageState(state) === 'yes'

export const inDetails = (state: State) => getRoute(state) === DETAILS_ROUTE.DETAILS

export const inRsvpForm = (state: State) => getRoute(state) === DETAILS_ROUTE.FORM

export const inTicketForm = (state: State) => getRoute(state) === DETAILS_ROUTE.TICKET_FORM

export const inPickTickets = (state: State) => getRoute(state) === DETAILS_ROUTE.PICK_TICKETS

export const getUrl = (
  state: State,
  route: DETAILS_ROUTE,
  routeParams: RouteParams,
  query: string = '',
  wixCodeApi: IWixAPI,
): {url: string; external?: boolean} => {
  const sectionPath = getSectionPath(state)
  if (!sectionPath) {
    return {url: undefined}
  }

  const url = `/${sectionPath}/${getSlug(state)}`
  switch (route) {
    case DETAILS_ROUTE.DETAILS:
      return {url: `${url}${query}`}
    case DETAILS_ROUTE.ORDER:
      if (isHeadlessSupportEnabled(state)) {
        const headlessUrl = getHeadlessUrl({
          query: wixCodeApi.location.query,
          baseUrl: wixCodeApi.location.baseUrl,
          navParams: {
            logicalName: NavigationType.EVENTS_THANK_YOU_PAGE,
            params: {
              reservationId: routeParams.reservationId,
            },
          },
        })
        if (headlessUrl) {
          return {url: headlessUrl, external: true}
        }
      }
      return {url: `${url}/${DETAILS_ROUTE.ORDER}/${routeParams.reservationId}/${routeParams.reservationState}${query}`}
    case DETAILS_ROUTE.THANK_YOU_MESSAGES:
      return {url: `${url}/${DETAILS_ROUTE.THANK_YOU_MESSAGES}/${routeParams.thankYouMessageState}${query}`}
    case DETAILS_ROUTE.ONLINE_CONFERENCING:
      return {url: `${url}/${DETAILS_ROUTE.ONLINE_CONFERENCING}/${routeParams.conferencingState ?? ''}`}
    default:
      return {url: `${url}/${route}${query}`}
  }
}

export const isOrderSuccess = (state: State) => state.navigation.reservationState === ReservationState.SUCCESS

export const isEventPreview = (state: State) => Boolean(state.navigation.query?.[draftEventPreviewUrlQueryParamName])

export const isChooseSeatMode = (state: State) => Boolean(state.navigation.query?.chooseSeat)
export const isChooseSeatNoFee = (state: State) => isChooseSeatMode(state)
