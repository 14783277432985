import {SeatingPlan} from '@wix/ambassador-seating-v1-seating-plan/types'
import {ListScheduleItemsResponse} from '@wix/ambassador-wix-events-agenda/types'
import {IPlatformAPI, IUser, ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {RsvpStatus, PlanList, TicketWithFormattedSalePeriod} from '@wix/events-types'
import {Group} from '@wix/ambassador-social-groups-web/types'
import {PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {TranslatedPricingDetails} from '@wix/pricing-plans-tpa-api'
import {setFormFactor} from '../../../../commons/actions/environment'
import {DETAILS_ROUTE} from '../../../../commons/constants/navigation'
import {ReservationState} from '../../../../commons/enums'
import {CommonState, CommonStoreExtraArgs, DemoEvents} from '../../../../commons/types/state'
import {addToCalendarClicked} from '../actions/calendar'
import {
  checkout,
  clearCheckout,
  editStep,
  getDiscount,
  handleNextStep,
  nextFormClicked,
  setExpandedTicketIndex,
  setTicketDetails,
  setUseBuyerDetails,
  setValidPaymentAdded,
  submitCheckoutStep,
} from '../actions/checkout'
import {publishComponentSettings, updateStyleParams} from '../actions/component'
import {applyCoupon, resetCouponCode} from '../actions/coupon'
import {getMembers, shareEvent} from '../actions/event'
import {clearInvoice} from '../actions/invoice'
import {detailsPageLoaded, formPageLoaded, thankYouPageLoaded, ticketedThankYouPageLoaded} from '../actions/loaded'
import {ensureLoginForMembersOnly, openMembersModal, promptLogin} from '../actions/members'
import {
  closeNativeModal,
  openCantCompletePaymentModal,
  openCheckoutUnavailable,
  openTicketsDetailsModal,
  openTicketsDownloadModal,
  openTimeExpiredModal,
  openRecurringEventsModal,
  openSeatingPlan,
  openConfirmRsvp,
  openHeadlessErrorModal,
} from '../actions/modals'
import {
  navigate,
  navigateToChangeRsvp,
  navigateToDetails,
  navigateToForm,
  navigateToHomePage,
  navigateToMainPage,
  navigateToOrder,
  navigateToTicketsPicker,
  navigateViaEditor,
} from '../actions/navigation'
import {downloadTickets} from '../actions/order-success'
import {applyPlan, openMembershipPicker} from '../actions/paid-plans'
import {paymentMethodSelected, placeOrderButtonClicked} from '../actions/payment'
import {getOrder, placeOrder, updateOrder} from '../actions/placed-order'
import {openPolicyModal, toggleAgreePolicies} from '../actions/policies'
import {registrationButtonClicked} from '../actions/registration'
import {cancelReservation, reserveTickets, clearReservationError} from '../actions/reservation'
import {
  changeMemberRsvp,
  changeResponse,
  deleteRsvp,
  editRsvpStep,
  handleNextRsvpStep,
  handleRSVP,
  resetRsvpError,
  sendRsvp,
  submitRsvpStep,
  updateRsvp,
  updateRsvpStatus,
} from '../actions/rsvp'
import {navigateToSchedulePage} from '../actions/schedule'
import {selectTicket, changeTicketDonation, setDonationError} from '../actions/selected-tickets'
import {updateSettings} from '../actions/settings'
import {collapseDescription, expandDescription} from '../actions/tickets-picker'
import {FormStep} from '../constants/constants'
import {Api} from '../utils/api'
import {joinGroup} from '../actions/groups'
import {setActiveElement} from '../../../../commons/actions/focus-handler'
import {FocusHandlerState} from '../../../../commons/reducers/focus-handler'
import {RESERVATION_ERROR} from '../reducers/reservation'
import {PageComponentSettings} from '../../stylesParams'
import {
  setShowPricingOptionsPlace,
  setShowMobileBasket,
  setShowAccessibilityMode,
  setShowPlaceDescription,
  selectPlace,
  unselectPlace,
} from '../reducers/seating/mode'
import {SEATING_ERROR, setSeatingError, setPlaceDonationError} from '../reducers/seating/errors'
import {setPlaceQuantity, addPlaceDonation, clickPlace, selectPlacePricingOption} from '../actions/seating/place'
import {seatingMapButtonClick} from '../actions/seating/bi'
import {selectPrice, selectLocation, resetFilters} from '../reducers/seating/filters'
import {updatePlace} from '../reducers/seating/places'
import {seatingCheckout} from '../actions/seating/checkout'
import {NativeModal} from '../components/modals/constants'
import {ScheduleItem} from './schedule'
import {RegFormData} from './registration'
import {RsvpErrorMetadata} from './errors'

export interface Actions {
  navigateToForm: typeof navigateToForm
  navigateToMainPage: typeof navigateToMainPage
  navigateToHomePage: typeof navigateToHomePage
  detailsPageLoaded: typeof detailsPageLoaded
  formPageLoaded: typeof formPageLoaded
  thankYouPageLoaded: typeof thankYouPageLoaded
  ticketedThankYouPageLoaded: typeof ticketedThankYouPageLoaded
  changeMemberRsvp: typeof changeMemberRsvp
  registrationButtonClicked: typeof registrationButtonClicked
  updateStyleParams: typeof updateStyleParams
  resetCouponCode: typeof resetCouponCode
  resetRsvpError: typeof resetRsvpError
  sendRsvp: typeof sendRsvp
  updateRsvp: typeof updateRsvp
  reserveTickets: typeof reserveTickets
  selectTicket: typeof selectTicket
  ensureLoginForMembersOnly: typeof ensureLoginForMembersOnly
  expandDescription: typeof expandDescription
  collapseDescription: typeof collapseDescription
  getMembers: typeof getMembers
  promptLogin: typeof promptLogin
  openMembershipPicker: typeof openMembershipPicker
  navigateToTicketsPicker: typeof navigateToTicketsPicker
  openCheckoutUnavailable: typeof openCheckoutUnavailable
  getOrder: typeof getOrder
  placeOrder: typeof placeOrder
  updateOrder: typeof updateOrder
  paymentMethodSelected: typeof paymentMethodSelected
  placeOrderButtonClicked: typeof placeOrderButtonClicked
  navigateToDetails: typeof navigateToDetails
  nextFormClicked: typeof nextFormClicked
  cancelReservation: typeof cancelReservation
  openHeadlessErrorModal: typeof openHeadlessErrorModal
  openTicketsDetailsModal: typeof openTicketsDetailsModal
  openTicketsDownloadModal: typeof openTicketsDownloadModal
  applyCoupon: typeof applyCoupon
  applyPlan: typeof applyPlan
  handleRSVP: typeof handleRSVP
  checkout: typeof checkout
  submitCheckoutStep: typeof submitCheckoutStep
  editStep: typeof editStep
  openTimeExpiredModal: typeof openTimeExpiredModal
  openCantCompletePaymentModal: typeof openCantCompletePaymentModal
  setValidPaymentAdded: typeof setValidPaymentAdded
  navigate: typeof navigate
  navigateViaEditor: typeof navigateViaEditor
  setUseBuyerDetails: typeof setUseBuyerDetails
  setTicketDetails: typeof setTicketDetails
  clearInvoice: typeof clearInvoice
  navigateToOrder: typeof navigateToOrder
  navigateToChangeRsvp: typeof navigateToChangeRsvp
  setExpandedTicketIndex: typeof setExpandedTicketIndex
  handleNextStep: typeof handleNextStep
  openMembersModal: typeof openMembersModal
  updateSettings: typeof updateSettings
  publishComponentSettings: typeof publishComponentSettings
  clearCheckout: typeof clearCheckout
  getDiscount: typeof getDiscount
  downloadTickets: typeof downloadTickets
  shareEvent: typeof shareEvent
  toggleAgreePolicies: typeof toggleAgreePolicies
  openPolicyModal: typeof openPolicyModal
  editRsvpStep: typeof editRsvpStep
  submitRsvpStep: typeof submitRsvpStep
  handleNextRsvpStep: typeof handleNextRsvpStep
  changeResponse: typeof changeResponse
  setFormFactor: typeof setFormFactor
  closeNativeModal: typeof closeNativeModal
  navigateToSchedulePage: typeof navigateToSchedulePage
  addToCalendarClicked: typeof addToCalendarClicked
  openRecurringEventsModal: typeof openRecurringEventsModal
  joinGroup: typeof joinGroup
  openSeatingPlan: typeof openSeatingPlan
  openConfirmRsvp: typeof openConfirmRsvp
  setActiveElement: typeof setActiveElement
  selectPrice: typeof selectPrice
  selectLocation: typeof selectLocation
  setShowAccessibilityMode: typeof setShowAccessibilityMode
  setPlaceQuantity: typeof setPlaceQuantity
  selectPlace: typeof selectPlace
  unselectPlace: typeof unselectPlace
  setSeatingError: typeof setSeatingError
  resetFilters: typeof resetFilters
  seatingMapButtonClick: typeof seatingMapButtonClick
  clearReservationError: typeof clearReservationError
  changeTicketDonation: typeof changeTicketDonation
  setDonationError: typeof setDonationError
  addPlaceDonation: typeof addPlaceDonation
  setPlaceDonationError: typeof setPlaceDonationError
  setShowPricingOptionsPlace: typeof setShowPricingOptionsPlace
  updatePlace: typeof updatePlace
  setShowMobileBasket: typeof setShowMobileBasket
  setShowPlaceDescription: typeof setShowPlaceDescription
  clickPlace: typeof clickPlace
  seatingCheckout: typeof seatingCheckout
  selectPlacePricingOption: typeof selectPlacePricingOption
  updateRsvpStatus: typeof updateRsvpStatus
  deleteRsvp: typeof deleteRsvp
}

export interface State extends CommonState {
  event: ExtendedEvent
  demoEvents: DemoEvents
  navigation: Navigation
  component: DetailsPageComponent
  placedOrder: PlacedOrderState
  currentMemberDetails: CurrentMemberDetails
  membersAreaEnabled: boolean
  memberRsvp: MemberRsvp
  reservation: Reservation
  checkoutOptions: CheckoutOptionsState
  selectedTickets: SelectedTickets
  pendingRequests: PendingRequests
  tickets: TicketWithFormattedSalePeriod[]
  ticketsPicker: TicketsPicker
  paidPlansEnabled: boolean
  paidPlans: PaidPlansState
  checkout: CheckoutState
  rsvp: RsvpState
  invoice: InvoiceState
  calendarLinks: wix.events.CalendarLinks
  guest: wix.events.rsvp.Rsvp
  policies: PoliciesState
  modals: ModalsState<NativeModal>
  schedule: ScheduleState
  currentUser: Partial<IUser>
  upcomingOccurrencesCount: number
  groups: GroupsState
  eventsMembersPage: EventsMembersPageState
  seating: SeatingState
  focusHandler: FocusHandlerState
  formSubmitErrors: FormSubmitErrors
}

export interface EventsMembersPageState {
  name: string
}

export interface SeatingState {
  plan: SeatingPlan
  places: import('@wix/wix-events-commons-statics').PlaceWithTicketInfo[]
  loading: boolean
  errors: SeatingErrors
  mode: SeatingMode
  filters: SeatingFilters
}

export interface SeatingMode {
  accessibility: boolean
  mobileBasket: boolean
  selectedPlaceId: string
  /* purposefully showDescription and showDescriptionPlace are separate - need to keep history of previously shown place for animations */
  showDescription: boolean
  descriptionPlace: PlaceWithTicketInfo
  showPricingOptionsPlace: PlaceWithTicketInfo
}

export interface SeatingFilters {
  selectedPrice: string
  selectedZone: string
}

export interface SeatingErrors {
  error: SEATING_ERROR
  donationErrors: Record<string, DONATION_ERROR>
}

export interface ScheduleState extends ListScheduleItemsResponse {
  items: ScheduleItem[]
}

export type ModalsState<ModalType extends NativeModal> = {
  modalType: ModalType
  payload?: ModalsPayload<ModalType>
}

export type ModalsPayload<ModalType> = ModalType extends NativeModal.HEADLESS_ERROR ? {message: string} : never

export interface PoliciesState {
  agreed: boolean
  policies: wix.events.Policy[]
  showAlert: boolean
}

export interface OrderedTicket extends TicketWithFormattedSalePeriod {
  orderedCount: number
  priceOverride: string
  pricingOptionId?: string
  seats?: SeatsInfo
}

export interface InvoiceState {
  error: string | null
  invoice: wix.events.ticketing.Invoice
  coupon: InvoiceCouponState
}

interface InvoiceCouponState {
  code: string
  ticketId: string
  submitted: boolean
  validating: boolean
}

export interface CheckoutState {
  useBuyerDetails: boolean
  currentStep: FormStep
  validPaymentAdded: boolean
  buyerDetails: RegFormData
  ticketsDetails: RegFormData[]
  ticketsDetailsValidity: boolean[]
  expandedTicketIndex: number
}

export interface FormSubmitErrors {
  error: RsvpErrorMetadata
  errorTime: Date
  canRetry: boolean
}

export interface RsvpState {
  currentStep: FormStep
  rsvpDetails: RegFormData
  response: RsvpStatus
}

export interface PlacedOrderState {
  order: wix.events.ticketing.Order
}

export interface PaidPlansState {
  planList: PlanList
  purchasedPlanList: PlanList
  selectedPaidPlanOrderId: string
  translatedDetails: Record<string, TranslatedPricingDetails>
}

export interface Reservation {
  data: ReservationData
  error: RESERVATION_ERROR
}

interface ReservationData {
  id: string
  expires: string
}

export type GetState = () => State

export interface StoreExtraArgs extends CommonStoreExtraArgs {
  serverApi: Api
  platformAPIs: IPlatformAPI
  flowAPI: ControllerFlowAPI
}

export interface Navigation extends RouteParams {
  sectionPath: string
  slug: string
  route: DETAILS_ROUTE
  query?: Record<string, string>
  shouldNavigateBack: boolean
}

export interface RouteParams {
  reservationId?: string
  reservationState?: ReservationState
  thankYouMessageState?: ThankYouMessageState
  conferencingState?: ConferencingState
}

export type ConferencingState = 'disabled' | 'provider-error'

export type ThankYouMessageState = 'yes' | 'no' | 'waiting'

export interface DetailsPageComponent {
  id: string
  settings: PageComponentSettings
}

export interface CurrentMemberDetails {
  firstName: string
  lastName: string
  email: string
  id: string
}

export interface MemberRsvp {
  rsvp: wix.events.rsvp.Rsvp
  loaded: boolean
}

export interface CheckoutOptionsState {
  loaded: boolean
  options: wix.events.ticketing.GetCheckoutOptionsResponse
}

export interface SelectedTickets {
  [id: string]: SelectedTicket
}

export interface SelectedTicket {
  quantity: number
  donation?: string
  donationError?: DONATION_ERROR
  pricingOptionIds?: string[]
}

export enum DONATION_ERROR {
  EMPTY_DONATION = 'EMPTY_DONATION',
  MINIMUM_NOT_REACHED = 'MINIMUM_NOT_REACHED',
}

export interface PendingRequests {
  requestNames: string[]
}

export interface TicketsPicker {
  tickets: {[ticketDefId: string]: TicketsPickerTicketInfo}
}

interface TicketsPickerTicketInfo {
  expandedDescription?: boolean
}

export interface GroupsState {
  installed: boolean
  group: Group
  updates: number
}

export interface SeatsInfo {
  placeIds: string[]
  donations: string[]
  pricingOptionIds: string[]
}

export interface TicketsToPlaces {
  [id: string]: SeatsInfo
}

export interface SelectedTicketInfo extends wix.events.ticketing.TicketDefinition {
  place?: PlaceWithTicketInfo
  pricingOptionId?: string
}
